import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'
import SEO from '../components/seo'

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title="Blog Post" />
      <div
        css={css`
          margin: 0 auto;
          max-width: 960px;
        `}
      >
        <h1
          css={css`
            padding: ${rhythm(1)} 0 ${rhythm(1)};
          `}
        >
          {post.frontmatter.title}
        </h1>
        <div
          css={css`
            text-align: left;
          `}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
